<template>
  <div class="text-center">
    <vx-tooltip class="position-icon" :text="$t('button.edit')" position="top">
      <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord"
                    class="action-success"/>
    </vx-tooltip>
    <vx-tooltip class="position-icon" :text="$t('button.delete')" position="top">
      <feather-icon icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" class="action-err"
                    @click="confirmDeleteRecord"/>
    </vx-tooltip>

  </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord() {
      this.$router.push(`/admin/permission-function/edit?id=${this.params.data.id}`).catch(() => {
      });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('root.titleWarning'),
        text: `${this.$t('root.limitFunction')} "${this.params.data.functionName}"`,
        accept: this.deleteRecord,
        acceptText: this.$t('button.delete'),
        cancelText: this.$t('button.cancel')
      })
    },
    deleteRecord() {
      this.$vs.loading();
      this.$crm.post(`/permission-function/delete/${this.params.data.id}`).then(() => {
        this.$vs.loading.close();
        return this.showDeleteSuccess();
      }).catch((err) => {
        this.$vs.loading.close();
        return this.$vs.notify({
          text: err.response.data.message || err.response.data.error_description || err.response.data.error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
    },
    showDeleteSuccess() {
      this.$store.commit('UPDATE_PERMISSION_SUCCESS', true);
      this.$vs.notify({
        color: 'success',
        text: this.$t('root.removeFunction'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
      })
    }
  }
}
</script>
